import React, {
  memo,
  useCallback,
  useContext,
  Fragment,
  useState,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { useHistory, useLocation } from "react-router";
import { AuthTemplate as Template, Box, Text } from "@cyberalarm/ui";
import {
  Scope,
  CookiesType,
  setCookie,
  getCookie,
  expireOption,
  expireCookie,
  Role,
} from "@cyberalarm/common";

import { StatusBar, UserSideBar } from "../organisms";

import { StoreContext, selectors, ResponsiveContext } from "store";
import { AdminRoutes, CommonRoutes, UserRoutes } from "routes";
import { Control } from "ui";
import { useIntl, defineMessages } from "react-intl";

const BodyRoot = styled(Box)`
  height: 100%;
`;

const HeaderBox = styled(Box)`
  flex-direction: row;
  justify-contents: space-between;
`;

const MenuBox = styled(Box)`
  flex-direction: row;
  justify-contents: space-between;
  margin-right: 25px;
`;
const MenuItem = styled(Box)`
  cursor: pointer;
  flex-direction: row;
  align-items: right;
  justify-content: right;
  margin-left: 25px;
  margin-top: 25px;
  color: ${themeGet("colors.black")};
  transition: 0.2s;
  :hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  height: 100%;
  width: fit-content;
  color: ${themeGet("colors.gray")};
`;

const Image = styled.img`
  height: 100%;
  object-fit: contain;
`;

interface AuthTemplateProps {
  children: React.ReactNode;
}

const { profile, team, dashboard } = defineMessages({
  profile: {
    defaultMessage: "Profile",
    id: "menuBarDesktop.profile",
  },
  team: {
    defaultMessage: "Settings",
    id: "menuBarDesktop.team",
  },
  dashboard: {
    defaultMessage: "Dashboard",
    id: "menuBarDesktop.dashboard",
  },
});

const BaseComponent = ({ children }: AuthTemplateProps) => {
  const history = useHistory();
  const intl = useIntl();
  const store = useContext(StoreContext);
  const scope = selectors.getUserScope(store);
  const isPreview = scope === Scope.Preview;
  const user = selectors.getUser(store);
  const location = useLocation();
  const [showTeamLink, setShowTeamLink] = useState(false);
  const showProfileLink = location.pathname !== "/user/profile";
  const showDashboardLink =
    location.pathname === "/user/profile" || location.pathname === "/user/team";

  useEffect(() => {
    if (user?.teamAttributes?.role === "admin") {
      setShowTeamLink(true);
    }
  }, [user]);

  const backToAdmin = useCallback(() => {
    const adminToken = getCookie(CookiesType.adminToken) as string;
    const adminRefreshCookie = getCookie(
      CookiesType.adminRefreshToken
    ) as string;
    setCookie(CookiesType.token, adminToken, expireOption);
    setCookie(CookiesType.refreshToken, adminRefreshCookie);
    setCookie(CookiesType.role, Role.admin);

    store.setUser(null);
    store.setUser({ role: Role.admin }); // reset user data

    expireCookie(CookiesType.adminToken, expireOption);
    expireCookie(CookiesType.adminRefreshToken);
    history.push(AdminRoutes.userList);
  }, [history, store]);
  const { isTablet } = useContext(ResponsiveContext);

  return (
    <>
      {!isTablet && (
        <HeaderBox>
          <Box
            height="10vh"
            alignItems="flex-start"
            flex={1}
            paddingLeft="25px"
          >
            <StyledLink to={CommonRoutes.root}>
              <Image
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt="CYBER ALARM"
                width="125px"
                height="65px"
              />
            </StyledLink>
          </Box>
          <MenuBox>
            {showDashboardLink && (
              <MenuItem>
                <StyledLink to={UserRoutes.domainDashboard}>
                  <Text>{intl.formatMessage(dashboard)}</Text>
                </StyledLink>
              </MenuItem>
            )}
            {showTeamLink && location.pathname !== "/user/team" && (
              <MenuItem>
                <StyledLink to={UserRoutes.team}>
                  <Text>{intl.formatMessage(team)}</Text>
                </StyledLink>
              </MenuItem>
            )}
            {showProfileLink && (
              <MenuItem>
                <StyledLink to={UserRoutes.profile}>
                  <Text>{intl.formatMessage(profile)}</Text>
                </StyledLink>
              </MenuItem>
            )}
            <Box marginLeft="25px">
              <Control />
            </Box>
          </MenuBox>
        </HeaderBox>
      )}

      <Template
        background={isPreview ? "brand" : "backgrounds.1"}
        sidebar={<UserSideBar />}
      >
        <Fragment>
          {isPreview && (
            <StatusBar
              handleLogout={backToAdmin}
              name={
                user && user.firstName
                  ? `${user.firstName} ${user && user.lastName}`
                  : user && user.email
              }
            />
          )}
          <BodyRoot
            paddingX={5}
            paddingBottom={5}
            marginTop={isPreview ? 0 : 0}
            overflowY="auto"
          >
            {isTablet && (
              <Box
                height="54px"
                alignItems={["center", "flex-start"]}
                marginBottom={5}
                marginTop={5}
                minHeight="54px"
              >
                <StyledLink to={CommonRoutes.root}>
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                    alt="CYBER ALARM"
                  />
                </StyledLink>
              </Box>
            )}
            {children}
          </BodyRoot>
        </Fragment>
      </Template>
    </>
  );
};

export const UserPageTemplate = memo(BaseComponent) as React.ComponentType<
  AuthTemplateProps
>;
