import { pathOr } from "ramda";
import { defineMessages } from "react-intl";

export const { title, goodNews, allTests, printReport } = defineMessages({
         title: {
           defaultMessage:
             "{status, select, failed {Urgent action required - Alarms} warnings {Action required - Warnings} passed {No action required - Secure} other {Urgent action required - Alarms}}",
           id: "dashboardActions.title",
         },
         goodNews: {
           defaultMessage: "Good news, you’re all secure",
           id: "dashboardActions.goodNews",
         },
         allTests: {
           defaultMessage: "Advanced",
           id: "dashboardActions.allTests",
         },
         printReport: {
           defaultMessage: "Print",
           id: "dashboardActions.printReport",
         },
       });

export const getFailedCount = pathOr(0, ["meta", "failedCount"]);
export const getPassedCount = pathOr(0, ["meta", "passedCount"]);
export const getWarningsCount = pathOr(0, ["meta", "warningsCount"]);
export const getLastScanAt = pathOr(null, ["meta", "lastScanAt"]);
