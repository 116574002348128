import React, {
  useCallback,
  Fragment,
  memo,
  useRef,
  useMemo,
  useContext,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Text,
  Modal,
  Card,
  Accordion,
  Icon,
  Button,
  Textarea,
  ArrowIconRoot,
  Box,
} from "@cyberalarm/ui";
import {
  getCapitalize,
  StatusColors,
  Status,
  Icons,
  useToggle,
  api,
} from "@cyberalarm/common";
import themeGet from "@styled-system/theme-get";
import styled from "styled-components";
import { mutate, cache } from "swr";
import { StoreContext } from "store";
import { ModalCheckCardHeader } from "../modal-check-card-header";
import {
  SectionItem,
  SectionRoot,
  SectionLabel,
  SectionDescription,
} from "../modal-preview-components";

import { i18n, actions } from "./schema";

import { useMappedTranslation } from "utils/use-mapped-translation";

const TextButton = styled(SectionDescription)`
  cursor: pointer;
  margin-bottom: 8px;
  font-size: 16px;
  color: ${themeGet("colors.black")};
  :hover {
    text-decoration: underline;
  }
`;
interface CheckDetailsProps {
  close: () => void;
  isOpen: boolean;
  details?: CheckResult;
  dontShowManageNotification?: boolean;
}

export const CheckDetails = memo(
  ({
    details,
    close,
    isOpen,
    dontShowManageNotification,
  }: CheckDetailsProps) => {
    const intl = useIntl();
    const { sortPreferences } = useContext(StoreContext);
    const domainListSort = `${sortPreferences.sortOption}_${sortPreferences.sortOrder}`;
    const inputReference = useRef<null | HTMLTextAreaElement>(null);
    const [isAccordionOpen, toggleAccordion] = useToggle();
    const problem = useMappedTranslation(
      details?.checkDescription?.problem,
      details?.variables
    );
    const description = useMappedTranslation(
      details?.checkDescription?.description,
      details?.variables
    );
    const additionalInfo = useMappedTranslation(
      details?.checkDescription?.additionalInfo,
      details?.variables
    );
    const title = useMappedTranslation(
      details?.checkDescription?.title,
      details?.variables
    );
    const action = useMappedTranslation(
      details?.checkDescription?.action,
      details?.variables
    );

    const background = useMemo(
      () =>
        details ? (StatusColors[details.status as Status] as string) : "logo",
      [details]
    );

    const copy = useCallback(() => {
      const node = inputReference.current;
      if (node) {
        node.select();
        document.execCommand("copy");
      }
    }, []);

    const completeThisAction = useCallback(async () => {
      await api(`domains/${details?.domain.id}/results/${details?.id}`, {
        method: "PUT",
      });

      const keys = cache.keys();

      const filteredKeys = keys.filter((key) => {
        const res = key.match(/^domains\/[0-9]+\/results\?limit=15/g);
        return res !== null;
      });
      filteredKeys.push(`domains?sort=${domainListSort}`);
      filteredKeys.forEach((key) => {
        mutate(key);
      });
      close();
    }, [details, close, domainListSort]);

    return (
      <Modal
        isOpen={isOpen}
        close={close}
        maxWidth="900px"
        width={{ xs: "100%", md: "70%" }}
      >
        <Card
          p={0}
          paddingBottom={7}
          height="100%"
          width="100%"
          overflow="auto"
          display="block"
        >
          {details && (
            <Fragment>
              <Box px={"32px"} py="35px" flex={1}>
                <ModalCheckCardHeader
                  subGroupTitle={details?.check?.template?.checkSubgroup?.title}
                  groupTitle={
                    details?.check?.template?.checkSubgroup?.checkGroup?.title
                  }
                  statusBackground={background}
                  header={details?.domainName}
                  title={title}
                />
              </Box>
              <SectionRoot>
                <Box mt="35px" paddingX={[5, 8]} paddingBottom={3} flex={1}>
                  {details?.checkDescription?.problem && (
                    <SectionItem text={problem} label={i18n.problemLabel} />
                  )}
                  {details?.checkDescription?.description && (
                    <SectionItem
                      text={description}
                      label={i18n.descriptionLabel}
                    />
                  )}
                  {details?.checkDescription?.additionalInfo && (
                    <SectionItem
                      text={additionalInfo}
                      label={i18n.additionalInfoLabel}
                    />
                  )}
                </Box>
              </SectionRoot>
              <SectionRoot
                flex={1}
                paddingX={[5, 8]}
                paddingY={5}
                minHeight="fit-content"
              >
                <Text fontSize="16px" fontWeight={600}>
                  <FormattedMessage {...i18n.actionLabel} />
                </Text>

                {details?.check?.template?.difficulty && (
                  <Box marginY={3} flex={1}>
                    <Text fontSize="16px" fontWeight={600}>
                      {intl.formatMessage(
                        actions[
                          `difficulty${getCapitalize(
                            details?.check?.template?.difficulty
                          )}`
                        ]
                      )}
                    </Text>
                  </Box>
                )}
                {details?.checkDescription?.action && (
                  <SectionDescription fontSize="16px">
                    {action ?? ""}
                  </SectionDescription>
                )}
              </SectionRoot>

              <Accordion
                isOpen={isAccordionOpen}
                onClick={toggleAccordion}
                root={
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    marginX={[5, 8]}
                    justifyContent="space-between"
                    flex={1}
                  >
                    <SectionLabel>
                      <FormattedMessage {...i18n.moreDetailsLabel} />
                    </SectionLabel>

                    <ArrowIconRoot isOpen={isAccordionOpen}>
                      <Icon name={Icons.arrow} />
                    </ArrowIconRoot>
                  </Box>
                }
              >
                <Box marginX={[5, 8]} marginTop={5} flex={1}>
                  <Textarea
                    valid={true}
                    readOnly
                    value={details ? details.info : ""}
                    ref={inputReference}
                    style={{ fontSize: "16px" }}
                  />
                  <Button onClick={copy} variant="primary" marginTop="20px">
                    <FormattedMessage {...i18n.copyText} />
                  </Button>
                </Box>
              </Accordion>

              {dontShowManageNotification ? null : (
                <SectionRoot mt="35px" pt="35px" px="65px">
                  <Text fontSize="18px" fontWeight={600} mb="20px">
                    <FormattedMessage {...i18n.manageNotifications} />
                  </Text>
                  <TextButton fontSize="18px" onClick={completeThisAction}>
                    <FormattedMessage {...i18n.completeThisAction} />
                  </TextButton>
                </SectionRoot>
              )}
            </Fragment>
          )}
        </Card>
      </Modal>
    );
  }
);

CheckDetails.displayName = "CheckDetails";
