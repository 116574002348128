import React, { memo, lazy } from "react";
import { Switch, Route } from "react-router-dom";

import { Profile, Dashboard, Team } from "features/user";
import { UserPageTemplate } from "ui";
import { UserRoutes } from "routes";

const TestDetails = lazy(() => import("features/user/test-list/details-index"));
const AlertsReport = lazy(() => import("features/user/report"));

export const UserPage = memo(() => {
  return (
    <UserPageTemplate>
      <Switch>
        <Route component={Dashboard} path={UserRoutes.domainDashboard} />
        <Route component={Profile} path={UserRoutes.profile} />
        <Route component={Team} path={UserRoutes.team} />
        <Route component={TestDetails} path={UserRoutes.domainTests} />
        <Route component={AlertsReport} path={UserRoutes.domainReport} />
      </Switch>
    </UserPageTemplate>
  );
});

UserPage.displayName = "UserPage";
